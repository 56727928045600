import React, { Fragment } from 'react'
import Page from '../../components/page'
import Stepper from '../../components/stepper'
import withRoot from '../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import {
  Typography,
  Button,
} from '@material-ui/core'

import { navigate } from 'gatsby'

import gallery from '../../static/getstarted/gallery.png'
import addcollection from '../../static/getstarted/addcollection.png'
import install05 from '../../static/fitface-install/05.jpg'
import install06 from '../../static/fitface-install/06.jpg'
import install07 from '../../static/fitface-install/07.jpg'

const styles = theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  parag: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
})

@withRoot
@withStyles(styles)
export default class Collections extends React.Component {
  render() {
    const { classes } = this.props

    const STEPS = [
      {
        label: (<Typography>Explore our <a className={classes.link} href="https://fitface.xyz/gallery" target="_blank" rel="noopener noreferrer">FitFace gallery</a>.</Typography>),
        content: (
          <Typography>At this moment, we have more then 3000 published designs in our gallery created by our ever growing numbers of creative watch face designers. You will probably find some that will fit your needs.</Typography>
        ),
        img: gallery
      },
      {
        label: 'Select a design',
        content: (
          <Fragment>
            <Typography>Just tap on the <b>+</b> button to add the design of your choice into your collection.</Typography>
            <Typography>A message will confirm the design has been added in your collection.</Typography>
          </Fragment>
        ),
        img: addcollection
      },
      { label: 'Open the settings of the FitFace clockface in the Fitbit app on your phone', content: '', img: '' },
      {
        label: 'Touch "Select another design"',
        content: '',
        img: install05
      },
      {
        label: 'Touch "My Favourites"',
        content: (
          <Fragment>
            <Typography>This section will display all the free designs that you have added.</Typography>
            <Typography>Tap on the &quot;Refresh the lists&quot; to see the updated list of your latest watch face collection.</Typography>
          </Fragment>
        ),
        img: install07
      },
      {
        label: 'Select a design',
        content: (
          <Fragment>
            <Typography>Tap on the name of the watchface. You will feel a small haptic feedback from your watch followed by a loading screen that will appear on your watch and phone.</Typography>
            <Typography>It should take less then a minute to load and thereafter, you will be able to enjoy the watch face design on your wrist!</Typography>
          </Fragment>
        ),
        img: install06
      },
      {
        label: 'You now have a new clock face on your watch. Good job and enjoy!',
        content: (
          <Typography>Lets see now how to buy and install a paid design.</Typography>
        ),
        img: ''
      },
    ]

    return (
      <Page toggleTheme={this.props.toggleTheme} title="How to install a design?">
        <Typography className={classes.parag}>
          Lets the fun part begin, we will explain you how to add designs in your collection and send them to your watch.
        </Typography>
        <Stepper steps={STEPS} lastStep={<Button variant="contained" color="primary" onClick={() => navigate('/user/buydesign')}>Buy a design</Button>} />
      </Page>
    )
  }
}
